<script setup>
import { onMounted, ref } from 'vue';

defineProps({
    id: String,
    modelValue: String,
});

defineEmits(['update:modelValue']);

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <input
        :id="id"
        ref="input"
        class="input is-rounded"
        type="text"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
    >
</template>
